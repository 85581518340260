/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react'

import createTopLayout from './TopLayout'
import theme from 'src/theme/theme'

const TopLayout = createTopLayout(theme)

export const wrapRootElement = ({ element, ...props }) => {
  return <TopLayout {...props}>{element}</TopLayout>
}
