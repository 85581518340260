import React from 'react'
import { Helmet } from 'react-helmet'

export default function BaseHelmet() {
  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" />
        <meta charset="utf-8" />
        <link rel="canonical" href="" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta name="format-detection" content="telephone=no"></meta>
      </Helmet>
    </React.Fragment>
  )
}
