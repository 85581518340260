import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import styledSanitize from 'styled-sanitize'
import BaseHelmet from './BaseHelmet'
import theme from '../../src/theme/theme'

const GlobalStyles = createGlobalStyle`
  /* Sanatize */
  ${styledSanitize}

  @font-face {
    font-family: 'Everett';
    font-weight: 400;
    font-style: 'normal';
    src: url('/assets/fonts/Everett-Regular-web.eot');
    src: url('/assets/fonts/Everett-Regular-web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Everett-Regular-web.ttf') format('truetype'),
         url('/assets/fonts/Everett-Regular-web.woff2') format('woff2'),
         url('/assets/fonts/Everett-Regular-web.woff') format('woff'),
         url('/assets/fonts/Everett-Regular-web.svg#svgFontName') format('svg');
  }

  @font-face {
    font-family: 'Everett';
    font-weight: 500;
    font-style: normal;
    src: url('/assets/fonts/Everett-Medium-web.eot');
    src: url('/assets/fonts/Everett-Medium-web.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Everett-Medium-web.ttf') format('truetype'),
         url('/assets/fonts/Everett-Medium-web.woff2') format('woff2'),
         url('/assets/fonts/Everett-Medium-web.woff') format('woff'),
         url('/assets/fonts/Everett-Medium-web.svg#svgFontName') format('svg');
  }

  * {
    outline: none
  }

  html {
    // word-break: normal;
    // hyphens: auto;
  }

`

export default function createTopLayout(themeConfig) {
  function TopLayout(props) {
    return (
      <React.Fragment>
        <BaseHelmet />
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {props.children}
        </ThemeProvider>
      </React.Fragment>
    )
  }

  TopLayout.propTypes = {
    children: PropTypes.node,
  }

  return TopLayout
}
