const palette = {
  primary: {
    main: '#ff3c19',
  },
  secondary: {
    main: 'black',
  },
}

const text = {
  main: 'black',
}

const typo = {
  primary: 'Everett',
  weight: {
    regular: '400',
    medium: '500',
  },
}

const button = {
  primary: {
    color: palette.primary.main,
  },
  secondary: {
    color: text.main,
  },
}

const breakpoints = {
  xs: '0px',
  sm: '600px',
  md: '1120px',
  lg: '1280px',
  xl: '1920px',
}

export default {
  palette: {
    ...palette,
    button,
    text,
  },
  typo: {
    ...typo,
  },
  breakpoints: {
    ...breakpoints,
  },
  icons: [
    'menu',
    'target',
    'moon',
    'plus',
    'chevron-left',
    'chevron-right',
    'x',
    'instagram',
    'youtube',
    'linkedin',
    'arrow-up-right',
  ],
  maxWidth: key => breakpoints[key],
  spacing: (x, unit) => x * 8 + (unit ? `${unit}` : 0),
}
